<template>
	<div class="page-container">
		<div class="page-section page-head">
			<div class="head-time">{{$t('liveplatform.data.renewText1')}} {{parseInt(Date.now()/1000) | parseTime()}}{{$t('liveplatform.data.renewText2')}}</div>
			<div class="head-wrap">
				<div class="head-main">
					<div class="user-info">
						<img class="pic" :src="userInfo.storeLogo" @error="imageLoadError" alt="">
						<div class="item-main">
							<div class="name">{{userInfo.userName}}</div>
							<div class="text">{{$t('liveplatform.data.tips1')}}</div>
						</div>
					</div>
				</div>
				<div class="head-box">
					<div id="myRadar" class="head-radar"></div>
				</div>
			</div>
		</div>
		<el-tabs v-model="pageTabActive" class="page-tabs" @tab-click="handleClickTab">
			<el-tab-pane :label="$t('liveplatform.data.tabPane1')" name="home">
				<div class="page-section page-market">
					<div class="section-head">
						<div class="title">
							<div>{{$t('liveplatform.data.pageTitle1')}}</div>
							<div class="subtitle">{{$t('liveplatform.data.pageSubTitle1')}}</div>
						</div>
						<div class="action">
							<ul>
								<li>
									<el-date-picker v-model="marketDate" type="date" size="small"
										value-format="yyyy-MM-dd" :placeholder="$t('liveplatform.placeholder.date')" :picker-options="pickerOptions"
										@change="handleMarketDateChange">
									</el-date-picker>
								</li>
							</ul>
						</div>
					</div>
					<div class="market-swiper">
						<swiper ref="mySwiper" :options="swiperOptions">
							<swiper-slide v-for="(item,index) in marketPanelOption" :key="index">
								<div class="field-item"><!-- :class="{active:marketIndex==index}" -->
									<div class="item-label">
										<span class="mr10">{{item.label}}</span>
										<el-popover width="300" trigger="hover" :content="item.tip"
											popper-class="live-popover-tip">
											<i slot="reference" class="el-icon-question"></i>
										</el-popover>
									</div>
									<div class="item-value">
										{{marketPanel[item.value]*1>0?marketPanel[item.value]:'-'}}
									</div>
								</div>
							</swiper-slide>
							<div class="swiper-button-prev" slot="button-prev"></div>
							<div class="swiper-button-next" slot="button-next"></div>
						</swiper>
					</div>

					<div class="market-thead">
						<div class="name">{{$t('liveplatform.data.pageTitle2')}}</div>
						<div class="action">
							<ul>
								<li>
									<el-radio-group v-model="marketRadio" size="small"
										@change="handleMarketRadioChange">
										<el-radio-button :label="7">{{$t('liveplatform.data.day7')}}</el-radio-button>
										<el-radio-button :label="15">{{$t('liveplatform.data.day15')}}</el-radio-button>
										<el-radio-button :label="30">{{$t('liveplatform.data.day30')}}</el-radio-button>
									</el-radio-group>
								</li>
								<li>
									<el-date-picker v-model="marketDaterange" type="daterange" size="small"
										value-format="yyyy-MM-dd" range-separator="-" :start-placeholder="$t('liveplatform.placeholder.startDate')"
										:end-placeholder="$t('liveplatform.placeholder.endDate')" :picker-options="pickerOptions"
										@change="handleMarketDaterangeChange">
									</el-date-picker>
								</li>
								<li class="type">
									<!-- <span :class="{active:tableType==1}" @click="handleTableTypeClick(1)">{{$t('liveplatform.data.chart')}}</span> | -->
									<span :class="{active:tableType==2}" @click="handleTableTypeClick(2)">{{$t('liveplatform.data.table')}}</span>
								</li>
								<li>
									<el-button type="info" size="small" @click="exportMarketList"><i
											class="el-icon-download"></i>{{$t('liveplatform.data.download')}}</el-button>
								</li>
							</ul>
						</div>
					</div>
					<div v-show="tableType==1">
						<div id="myChart" style="height: 400px;width: 1000px;margin: 0 auto;"></div>
					</div>
					<div v-show="tableType==2">
						<div class="page-table">
							<el-table :data="marketList" size="small" style="width: 100%">
								<el-table-column prop="day" :label="$t('liveplatform.tableHead.censusDay')" min-width="140px" fixed>
								</el-table-column>
								<el-table-column prop="open_times" :label="$t('liveplatform.tableHead.openTimes')" min-width="140px">
								</el-table-column>
								<el-table-column prop="visitors" :label="$t('liveplatform.tableHead.visitorsDay')" min-width="140px">
								</el-table-column>
								<el-table-column prop="new_fans" :label="$t('liveplatform.tableHead.newFansRoom')" min-width="140px">
								</el-table-column>
								<el-table-column prop="deal_num" :label="$t('liveplatform.tableHead.dealNumDay')" min-width="140px">
								</el-table-column>
								<el-table-column prop="deal_amount" :label="$t('liveplatform.tableHead.dealAmountDay')" min-width="140px">
								</el-table-column>
								<el-table-column prop="view_count" :label="$t('liveplatform.tableHead.viewCountDay')" min-width="140px">
								</el-table-column>
								<el-table-column prop="fans_visit_rate" :label="$t('liveplatform.tableHead.fansVisitRate')" min-width="140px">
								</el-table-column>
								<el-table-column prop="goods_click_uv" :label="$t('liveplatform.tableHead.goodsClickUv')" min-width="140px">
								</el-table-column>
								<el-table-column prop="goods_click_pv" :label="$t('liveplatform.tableHead.goodsClickPv')" min-width="140px">
								</el-table-column>
								<el-table-column prop="live_times" :label="$t('liveplatform.tableHead.liveTimes')" min-width="140px">
								</el-table-column>
							</el-table>
						</div>
						<div class="page-bottom">
							<el-pagination @size-change="handleMarketSizeChange"
								@current-change="handleMarketCurrentChange" :current-page.sync="marketPageCurr"
								:page-size="marketPageSize" layout="total, prev, pager, next" :prev-text="$t('liveplatform.pagination.prevText')"
								:next-text="$t('liveplatform.pagination.nextText')" :total="marketPageTotal">
							</el-pagination>
						</div>
					</div>
				</div>
				<div class="page-section">
					<div class="section-head">
						<div class="title">
							<div>{{$t('liveplatform.data.pageTitle3')}}</div>
							<div class="subtitle">{{$t('liveplatform.data.pageSubTitle3')}}</div>
						</div>
						<div class="action">
							<ul>
								<li>
									<el-input v-model="orderScreen.title" @change="getOrderList"
										:placeholder="$t('liveplatform.placeholder.goodsTitleID')" clearable size="small">
										<i class="el-icon-search el-input__icon" slot="suffix">
										</i>
									</el-input>
								</li>
								<li>
									<el-select v-model="orderScreen.time_type" size="small" @change="getOrderList">
										<el-option :label="$t('liveplatform.data.timeTypeOption1')" value="1"></el-option>
										<el-option :label="$t('liveplatform.data.timeTypeOption2')" value="2"></el-option>
									</el-select>
								</li>
								<li>
									<el-date-picker v-model="orderDaterange" type="datetimerange" size="small"
										value-format="timestamp" :range-separator="$t('liveplatform.placeholder.separator')" :start-placeholder="$t('liveplatform.placeholder.startDate')"
										:end-placeholder="$t('liveplatform.placeholder.endDate')" :picker-options="pickerOptions"
										@change="handleOrderDaterangeChange">
									</el-date-picker>
								</li>
								<li>
									<el-button type="info" @click="exportOrderList" size="small"><i
											class="el-icon-download"></i>{{$t('liveplatform.data.download')}}</el-button>
								</li>
							</ul>
						</div>
					</div>
					<div class="page-tip">
						<div class="text">{{$t('liveplatform.data.pageTips3')}}</div>
						<div class="bar">{{$t('liveplatform.data.more')}}</div>
					</div>
					<div class="page-table">
						<el-table :data="orderList" size="small">
							<el-table-column prop="title" :label="$t('liveplatform.tableHead.liveTitle')" fixed min-width="140px">
							</el-table-column>
							<el-table-column :label="$t('liveplatform.tableHead.openTime')" fixed min-width="140px">
								<template slot-scope="scope">
									{{scope.row.settime | parseTime()}}
								</template>
							</el-table-column>
							<el-table-column prop="live_id" :label="$t('liveplatform.tableHead.timesID')" width="140px">
							</el-table-column>
							<el-table-column prop="user_name" :label="$t('liveplatform.tableHead.userName')" width="140px">
							</el-table-column>
							<el-table-column prop="goods_id" :label="$t('liveplatform.tableHead.goodsID')" width="140px">
							</el-table-column>
							<el-table-column prop="goods_name" :label="$t('liveplatform.tableHead.goodsTitle')" width="140px">
							</el-table-column>
							<el-table-column prop="cate_name" :label="$t('liveplatform.tableHead.goodsCate')" width="140px">
							</el-table-column>
							<el-table-column prop="order_sn" :label="$t('liveplatform.tableHead.orderParent')" width="140px">
							</el-table-column>
							<el-table-column prop="rec_id" :label="$t('liveplatform.tableHead.orderSub')" width="140px">
							</el-table-column>
							<el-table-column :label="$t('liveplatform.tableHead.orderTime')" width="140px">
								<template slot-scope="scope">
									{{scope.row.order_time | parseTime()}}
								</template>
							</el-table-column>
							<el-table-column :label="$t('liveplatform.tableHead.payTime')" width="140px">
								<template slot-scope="scope">
									{{scope.row.pay_time | parseTime()}}
								</template>
							</el-table-column>
							<el-table-column prop="price" :label="$t('liveplatform.tableHead.payAmount')" width="140px">
							</el-table-column>
						</el-table>
					</div>
					<div class="page-bottom">
						<el-pagination @size-change="handleOrderSizeChange" @current-change="handleOrderCurrentChange"
							:current-page.sync="orderPageCurr" :page-size="orderPageSize"
							layout="total, prev, pager, next" :prev-text="$t('liveplatform.pagination.prevText')" :next-text="$t('liveplatform.pagination.nextText')" :total="orderPageTotal">
						</el-pagination>
					</div>

				</div>
			</el-tab-pane>
			<el-tab-pane :label="$t('liveplatform.data.tabPane2')" name="list">
				<div class="page-section">
					<div style="height: 30px;"></div>
					<!-- <div class="live-view">
						<div class="view-main">
							<img src="" alt="" class="pic">
							<div class="view-box">
								<div class="name"><el-tag type="danger" size="mini">直播中</el-tag><span>1321321</span></div>
								<div class="time">2022-01-12 16:30:13 开播</div>
							</div>
						</div>
						<div class="view-bar">
							<el-button type="danger" size="mini">实时场次数据</el-button>
						</div>
					</div> -->
					<div class="list-head">
						<div class="head-tabs">
							<ul>
								<li class="active">
									<span>{{$t('liveplatform.data.tabsName1')}}</span>
									<el-popover width="300" trigger="hover" :content="$t('liveplatform.data.tabsTips1')"
										popper-class="live-popover-tip">
										<i slot="reference" class="el-icon-question"></i>
									</el-popover>
								</li>
								<!-- <li>
									<span>{{$t('liveplatform.data.tabsName2')}}</span>
									<el-popover width="300" trigger="hover" :content="$t('liveplatform.data.tabsTips2')"
										popper-class="live-popover-tip">
										<i slot="reference" class="el-icon-question"></i>
									</el-popover>
								</li> -->
							</ul>
						</div>
						<div class="head-bar">
							<div class="bar-tit">
								<span>{{$t('liveplatform.data.tips2')}}</span>
								<el-date-picker v-model="timesDaterange" type="daterange" size="small"
									value-format="yyyy-MM-dd" :range-separator="$t('liveplatform.placeholder.separator')" :start-placeholder="$t('liveplatform.placeholder.startDate')"
									:end-placeholder="$t('liveplatform.placeholder.endDate')" :picker-options="pickerOptions"
									@change="handleTimesDaterangeChange">
								</el-date-picker>
							</div>
							<div class="bar-tip">{{$t('liveplatform.data.tips3')}}</div>
						</div>
					</div>
					<div class="section-head">
						<div class="title">
							<div>{{$t('liveplatform.data.pageTitle4')}}</div>
							<div class="subtitle">{{$t('liveplatform.data.pageSubTitle4')}}</div>
						</div>
						<div class="action">
							<ul>
								<li class="type">
									<span :class="{active:timesCheckAll==1}" @click="handleCheckAllTimesChange(1)">{{$t('liveplatform.data.checkAll')}}</span>|<span :class="{active:timesCheckAll==2}" @click="handleCheckAllTimesChange(2)">{{$t('liveplatform.data.uncheckAll')}}</span>
								</li>
							</ul>
						</div>
					</div>
					<div class="page-checkbox">
						<el-checkbox-group v-model="timesCheck" @change="handleCheckedTimesChange">
							<div class="item" v-for="(item,index) in timesOption" :key="index">
								<div class="item-label">{{item.title}}</div>
								<div class="item-block">
									<ul>
										<li v-for="(subItem,subIndex) in item.options" :key="subIndex">
											<el-checkbox :label="subItem.value">{{subItem.label}}</el-checkbox>
										</li>
									</ul>
								</div>
							</div>
						</el-checkbox-group>
					</div>
					<div class="section-head">
						<div class="title"></div>
						<div class="action">
							<ul>
								<li>{{$t('liveplatform.data.more')}}</li>
								<li>
									<el-button type="info" size="small" @click="exportTimesList"><i class="el-icon-download"></i>{{$t('liveplatform.data.download')}}</el-button>
								</li>
							</ul>
						</div>
					</div>
					<div class="page-table">
						<el-table :data="timesList" size="small">
							<el-table-column prop="title" :label="$t('liveplatform.tableHead.liveTitle')" fixed min-width="140px">
								<template slot-scope="scope">
									<span class="primary">{{scope.row.title}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="live_id" :label="$t('liveplatform.tableHead.timesID')" fixed min-width="140px">
							</el-table-column>
							<el-table-column prop="settime" :label="$t('liveplatform.tableHead.openTime')" min-width="140px">
							</el-table-column>
							<el-table-column prop="view_count" :label="$t('liveplatform.tableHead.viewCount')" min-width="140px"
								v-if="timesCheck.includes('view_count')">
							</el-table-column>
							<el-table-column prop="visitor_count" :label="$t('liveplatform.tableHead.visitors')" min-width="140px"
								v-if="timesCheck.includes('visitor_count')">
							</el-table-column>
							<el-table-column prop="new_fans" :label="$t('liveplatform.tableHead.newFans')" min-width="140px"
								v-if="timesCheck.includes('new_fans')">
							</el-table-column>
							<el-table-column prop="goods_click_uv" :label="$t('liveplatform.tableHead.goodsClickUv')" min-width="140px"
								v-if="timesCheck.includes('goods_click_uv')">
							</el-table-column>
							<el-table-column prop="goods_click_pv" :label="$t('liveplatform.tableHead.goodsClickPv')" min-width="140px"
								v-if="timesCheck.includes('goods_click_pv')">
							</el-table-column>
							<el-table-column prop="goods_click_rate" :label="$t('liveplatform.tableHead.goodsClickRate')" min-width="140px"
								v-if="timesCheck.includes('goods_click_rate')">
							</el-table-column>
							<el-table-column prop="turnover_rate" :label="$t('liveplatform.tableHead.guidedDealRate')" min-width="140px"
								v-if="timesCheck.includes('turnover_rate')">
							</el-table-column>
							<el-table-column prop="trans_count" :label="$t('liveplatform.tableHead.guidedDealNum')" min-width="140px"
								v-if="timesCheck.includes('trans_count')">
							</el-table-column>
							<el-table-column prop="trans_amount" :label="$t('liveplatform.tableHead.guidedDealAmount')" min-width="140px"
								v-if="timesCheck.includes('trans_amount')">
							</el-table-column>
						</el-table>
					</div>

					<div class="page-bottom line-bottom">
						<el-pagination @size-change="handleTimesSizeChange" @current-change="handleTimesCurrentChange"
							:current-page.sync="timesPageCurr" :page-size="timesPageSize"
							layout="total, prev, pager, next" :prev-text="$t('liveplatform.pagination.prevText')" :next-text="$t('liveplatform.pagination.nextText')" :total="timesPageTotal">
						</el-pagination>
					</div>

					<div class="section-head">
						<div class="title">
							<div>{{$t('liveplatform.data.pageTitle5')}}</div>
							<div class="subtitle">{{$t('liveplatform.data.pageSubTitle5')}}</div>
						</div>
						<div class="action">
							<ul>
								<li class="type">
									<span :class="{active:goodsCheckAll==1}" @click="handleCheckAllGoodsChange(1)">{{$t('liveplatform.data.checkAll')}}</span>|<span :class="{active:goodsCheckAll==2}" @click="handleCheckAllGoodsChange(2)">{{$t('liveplatform.data.uncheckAll')}}</span>
								</li>
							</ul>
						</div>
					</div>
					<div class="page-checkbox">
						<el-checkbox-group v-model="goodsCheck" @change="handleCheckedGoodsChange">
							<ul class="item-block">
								<li v-for="(item,index) in goodsOption" :key="index">
									<el-checkbox :label="item.value">{{item.label}}</el-checkbox>
								</li>
							</ul>
						</el-checkbox-group>
					</div>
					<div class="section-head">
						<div class="title"></div>
						<div class="action">
							<ul>
								<li>
									<el-select v-model="goodsScreen.live_id" size="small" clearable :placeholder="$t('liveplatform.placeholder.selectTimes')"
										@change="handleGoodsScreenChange">
										<el-option v-for="(item,index) in goodsTimes" :key="index" :label="'['+formatDate(item.start_time)+'] '+item.title"
											:value="item.live_id"></el-option>
									</el-select>
								</li>
								<li>
									<el-input v-model="goodsScreen.goods_desc" :placeholder="$t('liveplatform.placeholder.goodsTitleID')"
										size="small" @change="handleGoodsScreenChange">
										<i class="el-icon-search el-input__icon" slot="suffix">
										</i>
									</el-input>
								</li>
								<li>
									<el-button type="info" size="small" @click="exportGoodsList" ><i class="el-icon-download"></i>{{$t('liveplatform.data.download')}}</el-button>
								</li>
							</ul>
						</div>
					</div>
					<div class="page-tip">
						<div class="text"></div>
						<div class="bar">{{$t('liveplatform.data.more')}}</div>
					</div>
					<div class="page-table">
						<el-table :data="goodsList" size="small">
							<el-table-column :label="$t('liveplatform.tableHead.goodsTitleImage')" min-width="200px" fixed>
								<template slot-scope="scope">
									<div class="goods-item">
										<img class="pic" :src="scope.row.default_image" @error="imageErrorGoods" alt="">
										<div class="item-main">
											<div class="name">{{scope.row.goods_name}}</div>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="goods_id" :label="$t('liveplatform.tableHead.goodsID')" min-width="140px" fixed>
							</el-table-column>
							<el-table-column prop="title" :label="$t('liveplatform.tableHead.liveTitle')" min-width="140px">
							</el-table-column>
							<el-table-column prop="live_id" :label="$t('liveplatform.tableHead.timesID')" min-width="140px">
							</el-table-column>
							<el-table-column :label="$t('liveplatform.tableHead.openTime')" min-width="140px">
								<template slot-scope="scope">
									{{scope.row.settime | parseTime()}}
								</template>
							</el-table-column>
							<el-table-column prop="goods_click_pv" :label="$t('liveplatform.tableHead.goodsClickPv')" min-width="140px"
								v-if="goodsCheck.includes('goods_click_pv')">
							</el-table-column>
							<el-table-column prop="goods_click_uv" :label="$t('liveplatform.tableHead.goodsClickUv')" min-width="140px"
								v-if="goodsCheck.includes('goods_click_uv')">
							</el-table-column>
							<el-table-column prop="goods_sales_num" :label="$t('liveplatform.tableHead.guidedDealPiece')" min-width="140px"
								v-if="goodsCheck.includes('goods_sales_num')">
							</el-table-column>
							<el-table-column prop="trans_amount" :label="$t('liveplatform.tableHead.guidedDealAmount')" min-width="140px"
								v-if="goodsCheck.includes('trans_amount')">
							</el-table-column>
						</el-table>
					</div>

					<div class="page-bottom">
						<el-pagination @size-change="handleGoodsSizeChange" @current-change="handleGoodsCurrentChange"
							:current-page.sync="goodsPageCurr" :page-size="goodsPageSize"
							layout="total, prev, pager, next" :prev-text="$t('liveplatform.pagination.prevText')" :next-text="$t('liveplatform.pagination.nextText')" :total="goodsPageTotal">
						</el-pagination>
					</div>

				</div>
			</el-tab-pane>
		</el-tabs>
		<div class="page-section page-tabs"></div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import vueExcel from 'xlsx'
	import { mapGetters } from 'vuex'
	import { parseTime } from '@/utils/index';
	import { achievementPanel, achievementLive, achievementLiveOrder, achievementLiveTimes, achievementLiveGoods, fetchAchievementTimes } from '@/api/liveplatform';

	export default {
		computed: {
			...mapGetters([
				'userInfo'
			])
		},
		data() {
			let self = this;
			return {
				pageTabActive: 'home',
				pickerOptions: {
					disabledDate(time) {
						let yy = new Date().getFullYear();
						let mm = new Date().getMonth()-3;
						let dd = new Date().getDate()+1;
						let start_time = new Date(yy,mm,dd);
						let end_time = new Date(new Date().toLocaleDateString());
						return time.getTime()<start_time.getTime() || time.getTime() > end_time.getTime()-1000;
					},
				},
				swiperOptions: {
					// loop: true,
					slidesPerView: 5,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				},
				// 直播间大盘
				marketDate: parseTime(new Date(new Date().toLocaleDateString()).getTime()-1000,'{y}-{m}-{d}'),
				marketIndex: 0,
				marketDaterange: '',
				tableType: '2',
				marketRadio: 15,
				marketPanelOption: this.$t('liveplatform.dataJson1'),
				marketPanel: {},
				marketList: [],
				marketPageCurr: 1,
				marketPageSize: 10,
				marketPageTotal: 0,

				// 直播订单明细
				orderScreen: {
					title: '',
					time_type: '1',
				},
				orderDaterange: [
					Date.parse(parseTime(Date.now(),'{y}-{m}-{d}') + " 00:00:00") - 86400000 * 2,
					Date.parse(parseTime(Date.now(),'{y}-{m}-{d}') + " 00:00:00") - 1000
				],
				orderList: [],
				orderPageCurr: 1,
				orderPageSize: 10,
				orderPageTotal: 0,

				// 直播分场次效果
				timesDaterange: [
					parseTime(Date.now() - 86400000 * 16,'{y}-{m}-{d}'),
					parseTime(Date.now() - 86400000,'{y}-{m}-{d}')
				],
				timesOption: this.$t('liveplatform.dataJson2'),
				timesCheck: ['view_count', 'visitor_count', 'new_fans', 'goods_click_uv', 'goods_click_rate',
					'turnover_rate', 'trans_count', 'trans_amount'
				],
				timesCheckAll: 0, //0无 1全选 2不全选
				timesList: [],
				timesPageCurr: 1,
				timesPageSize: 10,
				timesPageTotal: 0,
				// 直播分商品效果
				goodsOption: this.$t('liveplatform.dataJson3'),
				goodsCheck: ['goods_click_pv', 'goods_click_uv', 'goods_sales_num', 'trans_amount'],
				goodsCheckAll: 1, //0无 1全选 2不全选
				goodsScreen: {
					live_id: '',
					goods_desc: '',
				},
				goodsTimes: [],
				goodsList: [],
				goodsPageCurr: 1,
				goodsPageSize: 10,
				goodsPageTotal: 0,

				domeList: [
					["2000-06-05", 116],
					["2000-06-06", 129],
					["2000-06-07", 135],
					["2000-06-08", 86],
					["2000-06-09", 73],
					["2000-06-10", 85],
					["2000-06-11", 73],
					["2000-06-12", 68],
					["2000-06-13", 92],
					["2000-06-14", 130],
					["2000-06-15", 245],
					["2000-06-16", 139],
					["2000-06-17", 115],
					["2000-06-18", 111],
					["2000-06-19", 309],
					["2000-06-20", 206],
					["2000-06-21", 137],
					["2000-06-22", 128],
					["2000-06-23", 85],
					["2000-06-24", 94],
					["2000-06-25", 71],
					["2000-06-26", 106],
					["2000-06-27", 84],
					["2000-06-28", 93],
					["2000-06-29", 85],
					["2000-06-30", 73],
					["2000-07-01", 83],
					["2000-07-02", 125],
					["2000-07-03", 107],
					["2000-07-04", 82],
					["2000-07-05", 44]
				],
			}
		},
		mounted() {
			// this.initEcharts();
			// this.initEchartsRadar();
			if (this.marketDate) {
				let startTime = new Date(this.marketDate).getTime() - 86400000 * this.marketRadio;
				this.marketDaterange = [parseTime(startTime,'{y}-{m}-{d}'), this.marketDate];
			}
			this.getMarketPanel();
			this.getMarketList();
			this.getOrderList();
			this.getTimesList();
			this.getGoodsList();
			this.getGoodsTimes();
		},
		methods: {
			imageLoadError(e) {
				e.target.src = this.$imageErrorAvatar;
				e.target.style.objectFit = 'fill';
			},
			imageErrorGoods(e) {
				e.target.src = this.$imageErrorGoods;
				e.target.style.objectFit = 'fill';
			},
			formatDate(time) {
				return parseTime(time)
			},
			// 导出
			excelExport(tableData, fileName) {
				let ws = vueExcel.utils.aoa_to_sheet(tableData);
				let wb = vueExcel.utils.book_new();
				vueExcel.utils.book_append_sheet(wb, ws, fileName);
				vueExcel.writeFile(wb, fileName + '.xlsx');
			},
			// 绘制雷达
			initEchartsRadar() {
				var myRadar = echarts.init(document.getElementById('myRadar'));
				myRadar.setOption({
					legend: {
						bottom: 5,
						data: this.$t('liveplatform.echarts.legendData1'),
						textStyle: {
							color: '#fff',
							fontSize: 14
						}
					},
					radar: {
						indicator: this.$t('liveplatform.echarts.indicator1'),
						shape: 'circle',
						splitNumber: 4,
					},
					series: [{
						type: 'radar',
						data: [{
								value: [1, 3.5, 5, 6, 8, 10],
								name: this.$t('liveplatform.echarts.legendData1')[0],
								areaStyle: {
									color: 'rgba(91, 143, 249, 0.6)'
								}
							},
							{
								value: [0.2, 2.5, 4, 7, 8.5, 9],
								name: this.$t('liveplatform.echarts.legendData1')[1],
								areaStyle: {
									color: 'rgba(90, 216, 166, 0.6)'
								}
							}
						]
					}]
				})
			},
			handleClickTab(tab, event) {},
			// 直播间大盘
			initEcharts() {
				const dateList = this.domeList.map(function(item) {
					return item[0];
				});
				const valueList = this.domeList.map(function(item) {
					return item[1];
				});
				var myChart = echarts.init(document.getElementById('myChart'));

				// 绘制图表
				myChart.setOption({
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						// type: 'time',
						data: dateList,
					},
					yAxis: {
						splitLine: {
							show: true,
							lineStyle: {
								color: "#666666"
							}
						}
					},
					series: [{
						data: valueList,
						type: 'line',
						showSymbol: false,
						smooth: true,
						itemStyle: {
							color: "$--live-red"
						},
						lineStyle: {
							color: "$--live-red"
						}
					}]
				});
			},
			handleMarketDateChange(val) {
				if (!val) {
					this.marketRadio = 0
					this.marketDaterange = '';
				}else {
					let startTime = new Date(val).getTime() - 86400000 * parseInt(this.marketRadio);
					this.marketDaterange = [parseTime(startTime, '{y}-{m}-{d}'), val];
				}
				this.marketPageCurr = 1;
				this.getMarketPanel();
				this.getMarketList();
			},
			handleMarketRadioChange(val) {
				if (this.marketDate) {
					let startTime = new Date(this.marketDate).getTime() - 86400000 * parseInt(val);
					this.marketDaterange = [parseTime(startTime, '{y}-{m}-{d}'), this.marketDate];
				}
				this.marketPageCurr = 1;
				this.getMarketPanel();
				this.getMarketList();
			},
			handleMarketDaterangeChange(val) {
				if (val) {
					this.marketDate = val[1];
					let long = Date.parse(val[1]) - Date.parse(val[0]);
					this.marketRadio = parseInt(long / 86400000);
				} else {
					this.marketRadio = 0;
				}
				this.marketPageCurr = 1;
				this.getMarketPanel();
				this.getMarketList();
			},
			handleTableTypeClick(type) {
				this.tableType = type;
			},
			getMarketPanel() {
				let param = {
					day: this.marketDate,
					app_key: this.$store.getters.appKey
				}
				achievementPanel(param).then(response => {
					this.marketPanel = response.data || {}
				})
			},
			getMarketList() {
				if (!this.marketDaterange) {
					this.marketList = [];
					return false;
				}
				let param = {
					begin_date: this.marketDaterange[0],
					to_date: this.marketDaterange[1],
					page_index: (this.marketPageCurr - 1) * this.marketPageSize,
					page_size: this.marketPageSize,
					app_key: this.$store.getters.appKey
				}
				achievementLive(param).then(response => {
					this.marketList = response.data.list || [];
					this.marketPageTotal = parseInt(response.data.count);
				})
			},
			exportMarketList() {
				if (!this.marketDaterange) {
					this.$message.error(this.$t('liveplatform.errorMsg.exportTime'));
					return false;
				}
				let loading = this.$loading();
				let param = {
					is_download: 1,
					begin_date: this.marketDaterange[0],
					to_date: this.marketDaterange[1],
					page_index: (this.marketPageCurr - 1) * this.marketPageSize,
					page_size: this.marketPageSize,
					app_key: this.$store.getters.appKey
				}
				achievementLive(param).then(response => {
					this.excelExport(response.data.list, this.$t('liveplatform.export.text1'));
					loading.close();
				}).catch(() => {
					loading.close();
				})
			},
			handleMarketSizeChange(val) {
				this.marketPageSize = val;
			},
			handleMarketCurrentChange(val) {
				this.marketPageCurr = val;
				this.getMarketList();
			},
			// 直播订单明细
			getOrderList() {
				if (!this.orderDaterange) {
					this.orderList = [];
					return false;
				}
				let param = {
					...this.orderScreen,
					begin_time: this.orderDaterange[0]/1000,
					to_time: this.orderDaterange[1]/1000,
					page_index: (this.orderPageCurr - 1) * this.orderPageSize,
					page_size: this.orderPageSize,
					app_key: this.$store.getters.appKey
				}
				achievementLiveOrder(param).then(response => {
					this.orderList = response.data.list || [];
					this.orderPageTotal = parseInt(response.data.total);
				})
			},
			handleOrderDaterangeChange(val) {
				this.orderPageCurr = 1;
				this.getOrderList();
			},
			handleOrderSizeChange(val) {
				this.orderPageSize = val;
			},
			handleOrderCurrentChange(val) {
				this.orderPageCurr = val;
				this.getMarketList();
			},
			exportOrderList() {
				let that = this;
				if (!this.orderDaterange) {
					this.$message.error(this.$t('liveplatform.errorMsg.exportTime'));
					return false;
				}
				let loading = this.$loading();
				let param = {
					...this.orderScreen,
					is_download: 1,
					begin_time: this.orderDaterange[0]/1000,
					to_time: this.orderDaterange[1]/1000,
					page_index: (this.orderPageCurr - 1) * this.orderPageSize,
					page_size: this.orderPageSize,
					app_key: this.$store.getters.appKey
				}
				achievementLiveOrder(param).then(response => {
					that.excelExport(response.data.list, this.$t('liveplatform.export.text2') + parseTime(Date.now(),'{y}-{m}-{d}'));
					loading.close();
				}).catch(() => {
					loading.close();
				})
			},
			// 直播分场次效果
			getTimesList() {
				let param = {
					begin_date: this.timesDaterange[0],
					to_date: this.timesDaterange[1],
					page_index: (this.timesPageCurr - 1) * this.timesPageSize,
					page_size: this.timesPageSize,
					app_key: this.$store.getters.appKey
				}
				achievementLiveTimes(param).then(response => {
					this.timesList = response.data.list || [];
					this.timesPageTotal = parseInt(response.data.total);
				})
			},
			handleTimesDaterangeChange(val) {
				if (val) {
					this.timesPageCurr = 1;
					this.goodsPageCurr = 1;
					this.getTimesList();
					this.getGoodsList();
					this.getGoodsTimes();
				}
			},
			handleTimesSizeChange(val) {
				this.timesPageSize = val;
			},
			handleTimesCurrentChange(val) {
				this.timesPageCurr = val;
				this.getTimesList();
			},
			handleCheckedTimesChange(val){
				let checkedCount = val.length;
				if(checkedCount>0){
					let checkOption = [];
					this.timesOption.forEach(item=>{
						item.options.forEach(subItem=>{
							checkOption.push(subItem.value)
						})
					})
					if(checkedCount===checkOption.length){
						this.timesCheckAll = 1;
					}else{
						this.timesCheckAll = 0;
					}
				}else {
					this.timesCheckAll = 2; // 不全选
				}
			},
			handleCheckAllTimesChange(val){
				this.timesCheckAll = val;
				let checkOption = [];
				if(val==1){
					this.timesOption.forEach(item=>{
						item.options.forEach(subItem=>{
							checkOption.push(subItem.value)
						})
					})
				}
				this.timesCheck = checkOption;
			},
			exportTimesList(){
				let loading = this.$loading();
				let param = {
					is_download: 1,
					begin_date: this.timesDaterange[0],
					to_date: this.timesDaterange[1],
					page_index: (this.timesPageCurr - 1) * this.timesPageSize,
					page_size: this.timesPageSize,
					app_key: this.$store.getters.appKey
				}
				achievementLiveTimes(param).then(response => {
					this.excelExport(response.data.list, this.$t('liveplatform.export.text3') + parseTime(Date.now(),'{y}-{m}-{d}'));
					loading.close();
				}).catch(() => {
					loading.close();
				})
			},
			// 直播分商品效果
			getGoodsList() {
				let param = {
					...this.goodsScreen,
					begin_date: this.timesDaterange[0],
					to_date: this.timesDaterange[1],
					page_index: (this.goodsPageCurr - 1) * this.goodsPageSize,
					page_size: this.goodsPageSize,
					app_key: this.$store.getters.appKey
				}
				achievementLiveGoods(param).then(response => {
					this.goodsList = response.data.list || [];
					this.goodsPageTotal = parseInt(response.data.total);
				})
			},
			getGoodsTimes() {
				let param = {
					begin_date: this.timesDaterange[0],
					to_date: this.timesDaterange[1],
					app_key: this.$store.getters.appKey
				}
				fetchAchievementTimes(param).then(response => {
					this.goodsTimes = response.data.list || [];
				})
			},
			handleGoodsSizeChange(val) {
				this.goodsPageSize = val;
			},
			handleGoodsCurrentChange(val) {
				this.goodsPageCurr = val;
				this.getGoodsList();
			},
			handleGoodsScreenChange() {
				this.goodsPageCurr = 1;
				this.getGoodsList();
			},
			handleCheckedGoodsChange(val){
				let checkedCount = val.length;
				if(checkedCount>0){
					let checkOption = [];
					this.goodsOption.forEach(item=>{
						checkOption.push(item.value)
					})
					if(checkedCount===checkOption.length){
						this.goodsCheckAll = 1;
					}else{
						this.goodsCheckAll = 0;
					}
				}else {
					this.goodsCheckAll = 2; // 不全选
				}
			},
			handleCheckAllGoodsChange(val){
				this.goodsCheckAll = val;
				let checkOption = [];
				if(val==1){
					this.goodsOption.forEach(item=>{
						checkOption.push(item.value)
					})
				}
				this.goodsCheck = checkOption;
			},
			exportGoodsList(){
				let loading = this.$loading();
				let param = {
					...this.goodsScreen,
					is_download: 1,
					begin_date: this.timesDaterange[0],
					to_date: this.timesDaterange[1],
					page_index: (this.goodsPageCurr - 1) * this.goodsPageSize,
					page_size: this.goodsPageSize,
					app_key: this.$store.getters.appKey
				}
				achievementLiveGoods(param).then(response => {
					this.excelExport(response.data.list, this.$t('liveplatform.export.text4') + parseTime(Date.now(),'{y}-{m}-{d}'));
					loading.close();
				}).catch(() => {
					loading.close();
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page-section {
		border-radius: 6px;
		background-color: $--live-background-default;
		margin-bottom: 20px;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .08);

		::v-deep .el-input {
			.el-input__inner {
				color: #FFFFFF;
				border-color: $--live-input-border;
				background-color: $--live-input-background;
			}
		}

		.section-head {
			display: flex;
			padding: 20px 30px 10px;
			align-items: center;
			justify-content: space-between;

			.title {
				font-size: 14px;
				color: #FFFFFF;
				line-height: 24px;

				.subtitle {
					font-size: 12px;
					color: $--live-link-color-4;
				}
			}

			.action {
				li {
					color: $--live-link-color-4;
					display: inline-block;
					margin-left: 15px;
				}

				li:first-child {
					margin-left: 0;
				}

				.type {
					color: #f0f0f0;

					span {
						color: #C0C0C0;
						font-size: 12px;
						padding: 0 10px;
						cursor: pointer;
					}

					span.active {
						color: $--live-link-hover-1;
					}
				}

				::v-deep .el-range-editor.el-input__inner {
					color: #FFFFFF;
					border-color: $--live-input-border;
					background-color: $--live-input-background;

					.el-range-separator {
						color: #FFFFFF;
					}

					.el-range-input {
						color: #FFFFFF;
					}
				}

				.el-button--info {
					color: #FFFFFF;
					border-color: $--live-button-block-border;
					background-color: $--live-button-block-background;
				}

				.el-button--info:hover {
					border-color: $--live-button-block-hover-border;
					background-color: $--live-button-gray-background;
				}
			}
		}
	}

	.page-head {
		padding: 30px;

		.head-time {
			color: $--live-link-color-3;
			text-align: right;
		}

		.head-wrap {
			display: flex;
			justify-content: space-between;

			.head-main {
				flex: 1;
				max-width: 600px;
				width: 0;

				.user-info {
					display: flex;
					align-items: center;

					.pic {
						width: 80px;
						height: 80px;
						border-radius: 40px;
						object-fit: cover;
					}

					.item-main {
						margin-left: 20px;

						.name {
							font-size: 14px;
							color: #FFFFFF;
							line-height: 18px;
						}

						.text {
							line-height: 18px;
							color: #FFFFFF;
							margin-top: 15px;
							font-size: 14px;
						}
					}
				}
			}

			.head-box {
				width: 330px;
				// height: 330px;
				padding: 20px 0 20px 20px;

				.head-radar {
					width: 100%;
					// height: 310px;
				}
			}
		}
	}

	.page-tabs {
		::v-deep .el-tabs__header {
			border-radius: 6px;
			background-color: $--live-background-default;
			box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .08);
			margin-bottom: 20px;

			.el-tabs__nav-wrap {
				padding: 0 30px;
			}

			.el-tabs__nav-wrap:after {
				background-color: transparent;
			}

			.el-tabs__item {
				height: 48px;
				line-height: 48px;
				color: $--live-tab-primary-active;
			}

			.el-tabs__active-bar {
				background-color: $--live-tab-primary-active;
			}
		}
	}

	.page-market {
		.market-swiper {
			padding: 0 24px;
			position: relative;

			.swiper-container {
				overflow: hidden;
			}

			.swiper-button-prev,
			.swiper-button-next {
				color: #FFFFFF;
				opacity: .6;
			}

			.swiper-button-prev:after,
			.swiper-button-next:after {
				font-size: 16px;
			}

			.swiper-button-prev {
				left: 0;
			}

			.swiper-button-next {
				right: 0;
			}
			.swiper-button-disabled {
				opacity: 0;
			}

			.field-item {
				padding: 14px 19px;
				margin: 0 5px;
				height: 65px;
				cursor: pointer;
				border: 1px solid $--live-gray-white-6;
				background-color: $--live-gray-white-6;

				.item-label {
					color: #FFFFFF;
					font-size: 14px;
					line-height: 20px;

					.mr10 {
						margin-right: 10px;
					}

					.el-icon-question {
						color: #D9D9D9;
						font-size: 16px;
					}
				}

				.item-value {
					height: 34px;
					line-height: 34px;
					margin-top: 10px;
					font-size: 26px;
					color: #FFFFFF;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			.field-item.active {
				border-color: $--live-swiper-active;
			}
		}

		.market-thead {
			display: flex;
			padding: 20px 30px;
			justify-content: space-between;
			align-items: center;

			.name {
				font-size: 14px;
				color: $--live-link-color-3;
			}

			.action {
				li {
					display: inline-block;
					margin-left: 15px;
				}

				li:first-child {
					margin-left: 0;
				}

				.type {
					color: #f0f0f0;

					span {
						color: #C0C0C0;
						font-size: 12px;
						padding: 0 10px;
						cursor: pointer;
						&.active {
							color: $--live-link-hover-1;
						}
					}
				}
			}

			::v-deep .el-radio-group {
				.el-radio-button {
					.el-radio-button__inner {
						color: #FFFFFF;
						border-color: $--live-gray-black-16;
						background-color: $--live-gray-black-16;
					}

					.el-radio-button__orig-radio:checked+.el-radio-button__inner {
						border-color: $--live-button-gray-border;
						background-color: $--live-button-gray-background;
						box-shadow: -1px 0 0 0 $--live-button-gray-background;
					}
				}
			}

			::v-deep .el-range-editor.el-input__inner {
				color: #FFFFFF;
				border-color: $--live-input-border;
				background-color: $--live-input-background;

				.el-range-separator {
					color: #FFFFFF;
				}

				.el-range-input {
					color: #FFFFFF;
				}
			}

			.el-button--info {
				color: #FFFFFF;
				border-color: $--live-button-block-border;
				background-color: $--live-button-block-background;
			}

			.el-button--info:hover {
				border-color: $--live-button-block-hover-border;
				background-color: $--live-button-block-hover-background;
			}
		}
	}

	.page-tip {
		display: flex;
		padding: 10px 30px;
		line-height: 12px;
		justify-content: space-between;

		div {
			font-size: 12px;
			color: $--live-link-color-4;
		}
	}

	.page-table {
		margin: 0 30px 30px;

		::v-deep .el-table {
			color: #FFFFFF;
			background-color: $--live-background-default;

			thead {
				color: #c0c0c0;
			}

			tr {
				background-color: $--live-background-default;
			}

			th.el-table__cell {
				background-color: $--live-background-default;
			}

			th.el-table__cell.is-leaf {
				border-color: $--live-table-border;
			}

			td.el-table__cell {
				border-color: $--live-table-border;
			}

			tr:hover>td.el-table__cell {
				background-color: $--live-background-default;
			}

			tr.hover-row>td.el-table__cell {
				background-color: $--live-background-default;
			}

			.el-table__fixed {
				height: 100%!important;
				// box-shadow: 2px 0 3px rgba(0, 0, 0, .12);

				td.el-table__cell {
					border-color: $--live-background-default;
				}
			}

			.el-table__fixed:before {
				background-color: $--live-background-default;
			}
		}

		::v-deep .el-table:before {
			background-color: $--live-background-default;
		}
		.primary {
			color: $--live-default;
		}
		.goods-item {
			display: flex;
			align-items: center;
			justify-content: center;

			.pic {
				width: 45px;
				height: 45px;
				object-fit: cover;
				margin-right: 12px;
			}

			.item-main {
				width: 0;
				flex: 1;

				.name {
					max-height: 36px;
					color: #2062e6;
					font-size: 12px;
					line-height: 18px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					cursor: pointer;
				}
			}
		}
	}

	.page-checkbox {
		padding: 0 30px;

		.item {
			font-size: 12px;

			.item-label {
				color: $--live-gray-white-54;
			}
		}

		.item-block {
			li {
				width: 160px;
				padding: 13px 0;
				display: inline-block;
			}
		}

		::v-deep .el-checkbox {
			.el-checkbox__input.is-checked .el-checkbox__inner {
				border-color: $--live-checkbox-primary;
				background-color: $--live-checkbox-primary;
			}
			.el-checkbox__label {
				color: #FFFFFF;
			}
		}
	}

	.live-view {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 30px 45px;
		padding: 10px 20px 10px 10px;
		background-color: $--live-gray-white-6;

		.view-main {
			flex: 1;
			width: 0;
			display: flex;
			align-items: center;

			.pic {
				width: 60px;
				height: 60px;
				margin-right: 20px;
				object-fit: cover;
			}

			.view-box {
				flex: 1;
				width: 0;

				.name {
					line-height: 20px;
					color: #FFFFFF;
					font-size: 14px;

					.el-tag--danger {
						border-radius: 10px;
						margin-right: 5px;
						color: #FFFFFF;
						border-color: transparent;
						padding: 0 10px;
						background: $--live-tag-danger-shading;
					}
				}

				.time {
					font-size: 12px;
					color: $--live-link-color-4;
					margin-top: 10px;
					line-height: 1em;
				}
			}
		}

		.view-bar {
			.el-button--danger {
				border-color: $--live-button-primary-border;
				background-color: $--live-button-primary-background;
			}
		}
	}

	.list-head {
		text-align: center;
		margin-bottom: 20px;
		position: relative;
		min-height: 40px;
		padding: 0 30px;
		margin-top: 18px;

		.head-tabs {
			width: 160px;
			border-radius: 20px;
			border: 1px solid $--live-tab-primary-active;
			box-sizing: border-box;
			display: inline-block;
			overflow: hidden;

			ul {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			li {
				color: $--live-tab-primary-active;
				font-size: 14px;
				flex-shrink: 0;
				width: 100%;
				height: 38px;
				line-height: 38px;
				text-align: center;
				background-color: $--live-background-default;
				cursor: pointer;
			}

			li.active {
				color: #FFFFFF;
				background-color: $--live-tab-primary-active;
			}
		}

		.head-bar {
			top: 0;
			right: 30px;
			position: absolute;
			color: $--live-link-color-4;
			padding-top: 4px;

			::v-deep .el-range-editor.el-input__inner {
				width: 250px;
				color: #FFFFFF;
				border-color: $--live-input-border;
				background-color: $--live-input-background;

				.el-range-separator {
					color: #FFFFFF;
				}

				.el-range-input {
					color: #FFFFFF;
				}
			}

			.bar-tit {
				span {
					font-size: 12px;
					margin-right: 10px;
				}
			}

			.bar-tip {
				text-align: right;
				line-height: 1em;
				margin-top: 8px;
				font-size: 12px;
			}
		}
	}

	.page-bottom {
		margin: 0 30px;
		padding-bottom: 30px;
		text-align: right;
		&.line-bottom {
			border-bottom: 1px solid $--live-info-color;
		}
		::v-deep .el-pagination {
			.el-pagination__total {
				color: $--live-gray-white-37;
			}

			.btn-next,
			.btn-prev {
				color: #FFFFFF;
				background-color: transparent;
				&:hover,&:hover {
					color: $--live-link-hover-1;
				}
			}

			button {
				&:disabled {
					color: $--live-text-color-2 !important;
					background-color: transparent;
				}
			}

			.el-pager {
				li {
					color: #FFFFFF;
					background-color: transparent;
					&:hover,&.active {
						color: $--live-link-hover-1;
					}
					&.btn-quicknext {
						color: $--live-text-color-2;
					}
				}
			}
		}
	}
</style>
